<template>
    <section style="background: #f9faf9;height:100%" class="forget-page">
        <div class="version">
            <img
                src="../assets/images/logo_bg.png"
                alt
                class="logo1"
                @click="goIndex"
            />
        </div>
        <!--  -->
        <div class="title">
            <h1>找回密码</h1>
        </div>
        <div class="center-conent">
            <div class="con con-forget">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="手机找回" name="second">
                        <el-form
                            :model="resetPhone"
                            :rules="rules"
                            ref="resetPhone"
                            style="padding:20px 0 10px 0px;"

                        >
                            <el-form-item label prop="phone" class="send-email">
                                <el-input
                                    v-model.trim="resetPhone.phone"
                                    class="phone"
                                    maxlength="11"
                                    autocomplete="off"
                                    placeholder="请输入手机号码"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label prop="code">
                                <el-input
                                    v-model.trim="resetPhone.code"
                                    class="code"
                                    maxlength="8"
                                    autocomplete="off"
                                    placeholder="请输入验证码"
                                ></el-input>
                                <el-button
                                    type="primary"
                                    class="code-button"
                                    @click="getCode('resetPhone')"
                                    style="width:122px;padding:0 10px; "
                                >{{ sendCode }}
                                </el-button
                                >
                            </el-form-item>
                            <el-form-item label prop="passWord">
                                <div class="flex_container">
                                <el-input
                                    placeholder="请输入密码"
                                    v-model.trim="resetPhone.passWord"
                                    show-password
                                    :maxlength="maxlength"
                                    class="pass-inp"
                                    autocomplete="off"
                                ></el-input>
                                <el-tooltip
                                    effect="light"
                                    placement="top-start"
                                    style="margin-left:4px;vertical-align: middle;"
                                    popper-class="atooltip"
                                    :tabindex="tabindex"
                                >
                                    <div slot="content" style="line-height:20px;">
                                        <i
                                                class="iconfont userchenggong1"
                                            style="font-size:24px;color:#67C23A;vertical-align: middle;"
                                            v-if="!(passStrong < 1)"
                                        ></i>
                                        <i
                                            class="iconfont usercuowu"
                                            style="font-size:24px;color:#F56C6C;vertical-align: middle;"
                                            v-if="passStrong < 1"
                                        ></i>
                                        长度为8-30个字符；
                                        <br/>
                                        <i
                                            class="iconfont userchenggong1"
                                            style="font-size:24px;color:#67C23A;vertical-align: middle;"
                                            v-if="passStrong == 3"
                                        ></i>
                                        <i
                                            class="iconfont usercuowu"
                                            style="font-size:24px;color:#F56C6C;vertical-align: middle;"
                                            v-if="passStrong != 3"
                                        ></i
                                        >必须包含大写字母、小写字母、数字、符号中至少3种
                                    </div>

                                    <span class="vol-tip"></span>
                                </el-tooltip>
                                </div>
                            </el-form-item>
                            <el-form-item label prop="newPassWord">
                                <el-input
                                    class="pass-inp"
                                    placeholder="请输入确认密码"
                                    v-model.trim="resetPhone.newPassWord"
                                    show-password
                                    style="width:420px"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button
                                    type="primary"
                                    class="phone-login"
                                    @click="codeReset('resetPhone')"
                                >确定
                                </el-button
                                >
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>

                <div class="longin">
                    <p>
                        <el-link
                            type="primary"
                            :underline="false"
                            @click="retreat"
                            class="top-conent-ssc-col"
                        >登录
                        </el-link
                        >
                    </p>
                </div>
            </div>
        </div>
        <div class="footer">
            <!-- <p>Copyright © 2013-2020 E-Hualu. All Rights Reserved. 易华录版权所有</p> -->
        </div>
    </section>
</template>

<script>
// import { sendEmail, codeForReset } from '@/api/forget';
// import { sendLoginCodeTrue } from '@/api/login';
// import { searchSwitchSet } from '@/api/PeopleCenter';
import Vue from 'vue';

var wait = 60;
var wait1 = 60;
export default {
    name: 'forget',
    data() {
        var validateConfirmPass = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请再次输入密码'));
            } else if (value !== this.resetPhone.passWord) {
                return callback(new Error('两次输入密码不一致!'));
            } else {
                return callback();
            }
        };
        var passStorngConfirm = (rule, value, callback) => {
            if (this.passStrong < 3) {
                return callback(new Error('密码强度不够！'));
            } else {
                this.$refs['resetPhone'].validateField('newPassWord');
                return callback();
            }
        };

        return {
            tabindex: -1,
            sendEmali: {
                email: ''
            },
            emailMsg: '',
            comfig: '',
            btnMsg: '确定',
            activeName: 'second',
            rules: {
                phone: [
                    // { required: true, message: "请输入电话号码", trigger: "blur" },orgPhone
                    {
                        required: true,
                        pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
                        message: '请输入正确的电话号码',
                        trigger: 'blur'
                    }
                ],
                code: [{required: true, message: '请输入验证码', trigger: 'change'}],
                passWord: [
                    {required: true, message: '请输入新的账号密码', trigger: 'change'},
                    {validator: passStorngConfirm, trigger: 'change'},
                    {
                        min: 8,
                        max: 30,
                        message: '长度在 5 到 30 个字符',
                        trigger: 'change'
                    }
                ],
                newPassWord: [
                    {required: true, validator: validateConfirmPass, trigger: 'change'}
                ]
            },
            resetPhone: {
                phone: '',
                code: '',
                passWord: '', //
                newPassWord: '',
                checkLowLevel: false
            },
            sendCode: '发送验证码',
            emailDisabled: false,
            type: 10,
            EmailType: 1,
            newSetObj: {},
            maxlength: 30
        };
    },
    created() {
        document.title = '超级存储云';
    },
    mounted() {
        // this.getSwitchMsg();
        // window.location.href = "/";
        setTimeout(() => {
            this.$refs.resetPhone && this.$refs.resetPhone.resetFields();
        }, 500)
    },
    methods: {
        getSwitchMsg() {
            this.$postJson('/').then(res => {
                if (res.code == 200) {
                    // console.log(eval(JSON.parse(res.data.configText)));
                    let obj = eval(JSON.parse(res.data.configText)); //configurationObj
                    this.$store.commit('getNewSet', obj);

                    this.newSetObj = eval(JSON.parse(res.data.configText));
                    if (this.newSetObj.passWordhint == 'developer') {
                        this.maxlength = 32;
                        this.resetPhone.checkLowLevel = true;
                    }
                }
            });
        },
        //tab栏点击事件
        handleClick(tab, event) {
            console.log(tab, event);
        },
        retreat() {
            //跳转门户
            window.location.href = this.newSetObj.kfGoindexurl
                ? this.newSetObj.kfGoindexurl
                : '/';
        },
        sendPassword() {
            if (this.btnMsg == '确定') {
                console.log(this.sendEmali.email);
                if (this.sendEmali.email == '') {
                    this.emailMsg = '请输入邮箱';
                    return;
                }
                var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
                if (!reg.test(this.sendEmali.email)) {
                    this.emailMsg = '请输入有效的邮箱';
                    return;
                }
                this.btnMsg = '发送中...';
                let str = location.href + 'Password';

                let data = {
                    mail: this.sendEmali.email,
                    type: this.EmailType,
                    domain: str.replace('?', '')
                };
                // sendEmail(data)
                this.$postJson('/')
                    .then(res => {
                        console.log(res);
                        if (res.code == 200) {
                            var _this = this;
                            let timeOut = setInterval(function () {
                                if (wait == 0) {
                                    _this.emailDisabled = false;
                                    _this.emailMsg = '';
                                    _this.btnMsg = '确定';
                                    wait = 60;
                                    clearInterval(timeOut);
                                } else {
                                    _this.emailMsg = '邮件已发送，请注意查收';
                                    _this.btnMsg = '已发送';
                                    wait--;
                                    // }
                                }
                            }, 1000);
                        } else {
                            this.emailDisabled = false;
                            this.btnMsg = '确定';
                            this.emailMsg = res.msg;
                        }
                    })
                    .catch(() => {
                        this.btnMsg = '确定';
                        this.emailMsg = '发送失败';
                    });
            }
        },
        //-------------------------------------验证码登录的部分
        //发送验证码
        getCode(formName) {
            if (this.sendCode == '发送验证码') {
                this.$refs[formName].validateField('phone', phonestrError => {
                    if (!phonestrError) {
                        var _this = this;
                        _this.sendCode = '发送中..';
                        this.$postJson('/accountUser/sendResetPasswordSms?', {phone: this.resetPhone.phone}).then(res => {
                            if (res.code === '200') {
                                let timeOut = setInterval(function () {
                                    if (wait1 == 0) {
                                        _this.sendCode = '发送验证码';
                                        wait1 = 60;
                                        clearInterval(timeOut);
                                    } else {
                                        _this.sendCode = wait1 + '秒重新获取';
                                        wait1--;
                                        // }
                                    }
                                }, 1000);
                            } else {
                                _this.sendCode = '发送验证码';
                            }
                        });
                    }
                });
            }
        },
        //登录验证
        codeReset(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let data = {
                        phone: this.resetPhone.phone,
                        code: this.resetPhone.code,
                        newPassword: this.resetPhone.passWord,
                    };
                    this.$postJson('/accountUser/resetPassword', data).then(res => {
                        if (res.code === '200') {
                            this.$confirm("修改成功,请登录", {
                                confirmButtonText: "确定",
                                type: "warning",
                                showClose: false,
                                isShowCancelBtn: false
                            })
                                .then(() => {
                                    this.$router.push('/login')
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    });
                }
            });
        },
        getset() {
            if (this.$store.state.newSetObj.kfShortNote == 'developer') {
                this.type = 11;
                this.EmailType = 2;
            } else {
                this.type = 10;
                this.EmailType = 1;
            }
        },
        goIndex() {
            if (this.newSetObj.goIndexUrl) {
                window.location.href = this.newSetObj.goIndexUrl;
            } else {
                window.location.href = '/';
            }
        }
    },
    computed: {
        passStrong() {
            let n = 0;
            if (!this.resetPhone.passWord) {
                n = -1;
            } else if (
                this.resetPhone.passWord.length < 8 ||
                this.resetPhone.passWord.length > this.maxlength
            ) {
                n = 0; //长度不符合直接返回0
            } else {
                if (/\d/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含数字
                if (/[a-z]/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含小写字母
                if (/[A-Z]/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含大写字母
                if (/\W/.test(this.resetPhone.passWord)) {
                    // if (/.*[~!@#\\$%&\\*]+.*/.test(this.resetPhone.passWord)) {
                    n++;
                } //包含其他字符
            }
            if (n > 3) {
                n = 3;
            }
            console.log(n)
            return n;
        },
        newName() {
            return this.sendEmali.email;
        }
    },
    components: {},
    watch: {
        //计算属性赋值newName
        newName(newName) {
            if (newName.length == 0) {
                this.emailMsg = '';
            }
        },
        '$store.state.newSetObj': function () {
            this.getset();
        }
    }
};
</script>

<style scoped lang="scss">
.footer {
    max-width: 1180px;
    margin: 40px auto;

    p {
        text-align: center;
        color: #999;
        font-size: 14px;
    }
}

section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.title {
    margin-top: 15vh;

    h1 {
        text-align: center;
        font-weight: normal;
        max-width: 1180px;
        margin: auto;
        font-size: 32px;
    }
}

.top-conent {
    width: 100%;
    background: #ff8700;
    height: 70px;
    line-height: 70px;
    color: #fff;
    display: flex;

    .logo3 {
        padding-left: 370px;
        padding-top: 20px !important;
    }
}

// .top-conent {
//   width: 100%;
//   background: #173259;
//   line-height: 50px;
//   height: 70px;
//   display: flex;
//   justify-content: space-between;
//   .top-left {
//     display: flex;
//     align-items: center;
//     .logo {
//       width: 28px;
//       height: 30px;
//       padding-left: 20px;
//     }
//     .logo2 {
//       padding-left: 370px;
//     }
//     .logotext {
//       margin: 0 5px;
//     }
//     .title {
//       font-size: 16px;
//     }
//     .dian {
//       width: 5px;
//       height: 5px;
//       border-radius: 50%;
//       background: #fff;
//       margin: 0 5px;
//     }
//     // &:hover {
//     //   cursor: pointer;
//     // }
//   }
// }
.center-conent {
    max-width: 1180px;
    margin: 40px auto;
    display: flex;
    justify-content: space-around;
    background: #fff;

    .con {
        width: 420px;
        padding-top: 50px;
        padding-bottom: 40px;

        h1 {
            padding-bottom: 30px;
            font-size: 24px;
            font-weight: normal;
        }

        .tost {
            // padding-left: 81px;
            color: #ccc;
        }

        .code {
            margin-right: 20px;
            width: 278px;
        }

        .code-button {
            background: #ff8700 !important;
            border: 1px solid #ff8700 !important;
            height: 40px;
        }

        .code-button:hover {
            background-color: #fdb057 !important;
            border-color: #fdb057 !important;
        }

        .email-input {
            width: 420px;
        }

        .send-btn,
        .send-email {
            height: 40px;
            width: 420px;
        }

        .phone-login {
            width: 420px;
            height: 40px;
            background: #ff8700 !important;
            border: 1px solid #ff8700 !important;
        }

        .phone-login:hover {
            background-color: #fdb057 !important;
            border-color: #fdb057 !important;
        }

        .pass-inp {
            width: 389px;
        }
    }
}

.longin {
    float: right;

    p:nth-child(2) {
        padding-right: 20px;
    }
}

.comfig {
    font-size: 14px;
    margin: 40px 0 10px 0px;
    // padding-left: 81px;
    width: 420px;
    word-break: break-word;
    line-height: 40px;
    color: #f00;
}

.passwordStrong-wrap {
    display: flex;
    line-height: 1;

    .passwordStrong {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .passwordStrong-color {
            display: flex;

            .color-wrap {
                width: 49px;
                height: 4px;
                background: #d4d3d4;
            }

            :first-child {
                border-radius: 100px 0px 0px 100px;
            }

            :last-child {
                border-radius: 0 100px 100px 0px;
            }
        }

        .passwordStrong-text {
            display: flex;
            margin-top: 10px;

            p {
                width: 49px;
                text-align: center;
            }
        }
    }
}

.top-conent-ssc-col .el-link--inner {
    color: #ff0 !important;
}

.version {
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
    font-size: 14px;
    height: 70px;
    width: 100%;
    background: #ff8700;

    .logo1 {
        margin-left: 370px;
        cursor: pointer;
        padding-top: 20px;
    }
}

.atooltip {
    background: #fff !important;
    color: #333333 !important;
    box-shadow: 0 0 5px #ccc;
    line-height: 28px;
    // border: 1px solid #303133 !important;
}

.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow {
    border-top: 3px solid #eeecec !important;
    //
}

.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow:after {
    border-top-color: #fff !important;
}
</style>
